import React from "react"
import "../../../style.scss"
import Layout from "../../../components/layout"
import hiyoko from "../../../images/img_hiyoko.png"

const IndexPage = () => {
  return (
    <Layout>
      <div className="section hero is-fullheight-with-navbar">
        <div className="hero-body">
          <div className="container has-text-centered">
            <figure class="image is-64x64" style={{ marginRight: "auto", marginLeft: "auto" }}>
              <img src={hiyoko} alt="hiyoko"/>
            </figure>
            <div className="title" style={{ color: "#ffc72e"}}>OUT OF RANGE!</div>
            <div className="subtitle" style={{ fontWeight: "bold" }}>提供エリア外です</div>
            <div className="text">配信元のホットスポットで</div>
            <div className="text">再度お試しください</div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default IndexPage